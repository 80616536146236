import { Box, Button } from '@mui/material'
import Grid from '@mui/material/Grid2';
import {useProjectApi} from "../../providers/ProjectApiProvider";
import useVisibilities from "../../hooks/useVisibilities";
import useQuotaExceededDialog from "../../hooks/useQuotaExceededDialog";
import { useCallback, useEffect, useMemo, useState } from 'react'
import {startMarginLine} from "../../services/apiService";
import MeshViewer from "../widgets/MeshViewer";
import {mediastoreUrl} from "../../services/FileService";
import config from "../../config";
import FileDownload from "./FileDownload";
import {getToothLabel, toFdi} from "../../utils/ToothNotation";
import StatusAndVisibility from "./StatusAndVisibility";
import { useProfile, useSettings } from '../../providers/UserProfileProvider'
import {FakeProgressBar} from "../widgets/FakeProgressBar";
import { TeethTabs } from '../widgets/TeethTabs';
import { TabPanel } from '../widgets/TabPanel';
import { GenerationFailedMessage } from '../widgets/GenerationFailedMessage';
import { MarginLineEditor } from '../margin-line-editor'
import '../margin-line-editor/index.css';

export default function MarginLine() {
  const {
    project: {
      id: projectId,
      upperArch,
      lowerArch,
      teethUnderRepair,
      toothRestorations,
      ownerOrganization: organizationId,
    },
  } = useProjectApi();

  const { user } = useProfile()

  const { isVisible, setVisible } = useVisibilities({
    upperArch: teethUnderRepair.some((x) => x <= 16),
    lowerArch: teethUnderRepair.some((x) => x > 16),
    ...Object.fromEntries(
      Object.keys(toothRestorations).map((key) => [`marginLine${key}`, true])
    ),
  });

  const { notation } = useSettings();

  function buildFilesToShow() {
    return [
      {
        name: mediastoreUrl(projectId, upperArch.file.decimatedFileName()),
        show: isVisible("upperArch"),
      },
      {
        name: mediastoreUrl(projectId, lowerArch.file.decimatedFileName()),
        show: isVisible("lowerArch"),
      },
      ...Object.values(toothRestorations).map((toothRestoration) => {
        return {
          name: mediastoreUrl(projectId, toothRestoration.marginLineFileName()),
          show:
            isVisible(`marginLine${toothRestoration.tooth}`)
            && toothRestoration.marginLine?.status === "success",
          color: config.colors.viewer.marginLine,
        };
      }),
    ];
  }

  function buildFiles() {
    return [
      ...Object.values(toothRestorations).map((toothRestoration) => {
        return {
          marginLineFileUrl: mediastoreUrl(projectId, toothRestoration.marginLineFileName()),
          dieFileUrl: mediastoreUrl(projectId, toothRestoration.dieFile.decimatedFileName()),
        };
      }),
    ];
  }

  const [loadErrors, setLoadErrors] = useState({})

  const [isEditable, setIsEditable] = useState(false)

  const [archFileUrl, setArchFileUrl] = useState('')

  useEffect(() => {
    let url = ''
    if (isVisible("upperArch")) {
      url = mediastoreUrl(projectId, upperArch.file.decimatedFileName())
    } else {
      url = mediastoreUrl(projectId, lowerArch.file.decimatedFileName())
    }

    setArchFileUrl(url)
  }, [lowerArch.file, upperArch.file])

  const getMarginLinePreview = useCallback((restoration, files, index) => {
    const status = restoration.marginLine?.status;
    const file = files[index]

    if (status === 'success' || isEditable) {
      return (
        <MarginLineEditor
          height="500px"
          archFileUrl={file.dieFileUrl || archFileUrl}
          marginLineFileUrl={file.marginLineFileUrl}
          editable={isEditable}
          accessToken={user.access_token}
          onLoadDieFileError={(error) => setLoadErrors(prevState => ({
            ...prevState,
            [index]: {
              ...prevState[index],
              die: error
            }
          }))}
          onLoadMarginLineFileError={(error) => setLoadErrors(prevState => ({
            ...prevState,
            [index]: {
              ...prevState[index],
              marginLine: error
            }
          }))}
        />
      )
      // return <MeshViewer width="100%" height="450px" files={files} />;
    }

    if (status === 'failed') {
      return (
        <GenerationFailedMessage
          actions={(
            <Button variant="contained" onClick={() => setIsEditable(true)}>
              Create margin line manually
            </Button>
          )}
        />
      )
    }

    return <FakeProgressBar />;
  }, [user.access_token, isEditable, archFileUrl])

  const showQuotaExceededDialog = useQuotaExceededDialog();

  useEffect(() => {
    const fetch = async () => {
      for (const x of Object.values(toothRestorations).filter(
        (x) => x.marginLine === null
      )) {
        try {
          await startMarginLine(projectId, x.tooth);
        } catch (e) {
          if (e.response.status === 429) {
            await showQuotaExceededDialog(organizationId);
          }
        }
      }
    }

    fetch()
  }, []);

  const Item = ({
    restoration,
    visible,
    setVisible,
    file,
    downloadName,
    status,
  }) => {
    const extension = file.split(".").pop();

    return (
      <Box display="flex" paddingX={2} alignItems={"center"}>
        <FileDownload
          projectId={projectId}
          file={file}
          downloadFilename={`${downloadName}${getToothLabel(
            restoration.tooth,
            notation
          )}.${extension}`}
          enabled={status === "success"}
        />
        <StatusAndVisibility
          status={status}
          visible={visible}
          setVisible={setVisible}
        />
      </Box>
    );
  };

  // const files = buildFilesToShow();

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  const restorations = useMemo(() => {
    return Object.values(toothRestorations)
  }, [toothRestorations])

  const [value, setValue] = useState(restorations[0]?.tooth);

  return (
    <Grid container spacing={3}>
      <Grid>
        <TeethTabs data={restorations} statusField="marginLine" tabTitle="Prep" value={value} onChange={changeTab} />
      </Grid>
      <Grid sx={{ flex: 1, minWidth: 0 }}>
        {restorations.map((restoration, index) => (
          <TabPanel activeTab={value} key={restoration.tooth} value={restoration.tooth}>
            <Box className="ui-scans-preview" sx={{ flex: 1 }}>
              {/* <MarginLineEditor height="500px" archUrl={fileUrl} curvePlyUrl={buildFile()[index].name} accessToken={user.access_token} /> */}
              {getMarginLinePreview(restoration, buildFiles(), index)}
            </Box>
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
